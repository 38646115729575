.tsquare {
  width: 50px;
  height: 50px;
  border: 1px solid black;
}

.game {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
}

.game-board {
  background-color: yellow;
}

.board-row {
  background-color: green;
}

.game-info {
  text-align: center;
  font-size: 15px;
}

.challenge-again {
  margin-top: 15px;
}

.messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 15px;
  color: white;
  display: inline-block;
  max-width: 75%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 0;
  text-align: left;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 5px 1%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #3e3e3e;
  letter-spacing: 0.1px;
  font-size: 12px;
  font-weight: bold;
}

.sentTextAI {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #5946ff;
  letter-spacing: 0.1px;
  font-size: 12px;
  font-weight: bold;
}

.pl-10 {
  padding-left: 5px;
}

.pr-10 {
  padding-right: 5px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.background-orange {
  background: #282828;
}

.backgroundLight {
  background: #f3f3f3;
}

.backgroundBlue {
  background: hsl(217, 91%, 91%);
}

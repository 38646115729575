:root {
  --width: 50px;
  --x-speed: 12s;
  --y-speed: 14s;
  --transition-speed: 2s;
  --circle-width: 20px;
}

* {
  box-sizing: border-box;
}

#matter-animation {
  position: relative;
}

.App {
  height: 100vh;
  box-sizing: border-box;
}

.y {
  animation: y 11s linear infinite alternate;
  position: absolute;
  width: var(--width);
}

.x {
  animation: x 8s linear infinite alternate;
  position: absoulte;
  width: var(--width);
}

.x2 {
  animation: x2 var(--x-speed) linear infinite alternate;
  width: var(--width);
  position: absolute;
}

.block {
  width: var(--width);
  height: var(--width);
  background: black;
  transition: border-radius var(--transition-speed) linear;
  cursor: pointer;
}

.block:hover {
  background: orange !important;
}

.block-2 {
  width: var(--width) !important;
  height: var(--width);
  transition: border-radius var(--transition-speed) linear;
  background: rgb(179, 181, 184);
  cursor: pointer;
}

.block-2:hover {
  background: orange !important;
}

.heart {
  position: relative;
  width: 90px;
  height: 80px;
  margin-top: 10px;
}

.heart::before,
.heart::after {
  content: "";
  position: absolute;
  top: 0;
  width: 42px;
  height: 70px;
  border-radius: 50px 50px 0 0;
  background: red;
}

.heart::before {
  left: 40px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.heart::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

img {
  width: 50%;
}

.circle {
  width: 20px;
  height: 20px;
  transition: border-radius var(--transition-speed) linear;
  border-radius: 50%;
  cursor: pointer;
}

.y-circle {
  animation: y-circle 11s linear infinite alternate;
  position: absolute;
  width: 0;
}

.x-circle {
  animation: x-circle 8s linear infinite alternate;
  position: absoulte;
  width: 0;
}

.block-btn {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
}

.circle-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.block-btn button:hover {
  box-shadow: 0 0 0 2px rgb(201, 155, 27);
  transition: 0.7s;
}

.block-btn button a {
  text-decoration: none;
  color: black;
}

.chat-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  text-align: right;
}

.chat-btn button {
  margin-top: 10px;
}

.top-neon-border {
  animation: pulsate 1.5s infinite alternate;
  border-top: 0.2rem solid #fff;
  padding: 0.4em;
  box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #0067ee,
    0 0 0.8rem #0067ee, 0 0 2.8rem #0067ee, inset 0 0 1.3rem #0067ee;
  padding: 0;
  margin: 0;
}

@keyframes x {
  100% {
    transform: translateX(calc(100vw - var(--width)));
  }
}

@keyframes y {
  100% {
    transform: translateY(calc(100vh - var(--width)));
  }
}

@keyframes x2 {
  100% {
    transform: translateX(calc(100vw - var(--width)));
  }
}

@keyframes x-circle {
  100% {
    transform: translateX(calc(100vw - var(--circle-width)));
  }
}

@keyframes y-circle {
  100% {
    transform: translateY(calc(100vh - var(--circle-width)));
  }
}

.pri {
  position: absolute;
  top: 30%;
  width: 100%;
}

.valentine-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.valentine-text {
  text-align: center;
}

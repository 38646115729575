#chat-messages .messages-cont {
  padding: 5% 0;
  overflow: auto;
  flex: auto;
  height: 280px;
  margin: 0;
  word-break: break-word;
  background-color: #d3d3d3;
}

@media (max-width: 1200px) {
  #chat-messages .messages-cont {
    padding: 2% 0;
    height: 280px;
  }
}

@media (max-width: 600px) {
  #chat-messages .messages-cont {
    height: 350px;
  }
}

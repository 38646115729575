.chat-input-form {
  display: flex;
  border-top: 2px solid #dcdcdc;
}

.chat-input-form .input {
  border: none;
  border-radius: 0;
  padding: 10px;
  width: 80%;
  font-size: 13px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.chat-input-form .sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #282828;
  display: inline-block;
  border: none;
  width: 20%;
  margin-top: 0 !important;
  border-bottom-right-radius: 3px;
}

.chat-input-form .sendButton:hover {
  background: #1e90ff;
}

#prii-page {
  background-color: #f0e6f6;
  color: #333;
  font-family: "Arial", sans-serif;
  text-align: center;
  height: 100vh;
  box-sizing: border-box;
}

#prii-page .prii-text-cont {
  padding: 15%;
  position: absolute;
}

#prii-page h1 {
  color: #d63384;
  font-size: 1.5em;
  margin-bottom: 20px;
}

#prii-page p {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 20px;
}

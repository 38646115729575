#chatv2-outer-cont {
  position: fixed;
  bottom: 0;
  right: 0;
}

#chatv2-outer-cont-small {
  position: relative;
  width: 100%;
  bottom: 0;
}

.neonText {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 10px #050051,
    0 0 10px #050051, 0 0 10px #050051, 0 0 10px #050051, 0 0 10px #050051;
}

.chatv2-inner-cont {
  background-color: #f8f8ff;
  animation: pulsate 1.5s infinite alternate;
  border: 0.2rem solid #fff;
  border-radius: 5px;
  padding: 0.4em;
  box-shadow: 0 0 0.4rem #fff, 0 0 0.4rem #fff, 0 0 0.4rem #0067ee,
    0 0 0.4rem #0067ee, 0 0 0.8rem #0067ee, inset 0 0 1rem #0067ee;
  width: 350px;
}

.Jarvis {
  position: relative;
  z-index: 999;
  /* top: 50%;
  left: 50%;
  transform: translate(-505, -50%); */
  width: 20px;
  height: 20px;
  background: #185dff;
  border: 2px solid #33ff00;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 0 5px #0036d7, 0 0 0 6px #56ff00;
}

.Jarvis:before,
.Jarvis:after {
  content: "";
  position: absolute;
  left: -15px;
  top: -15px;
  right: -15px;
  bottom: -15px;
  border: 2px solid #33ff00;
  border-radius: 50%;
  animation: animate 2s linear infinite;
}

.Jarvis:after {
  animation-delay: 1s;
}

@keyframes animate {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(0.7);
  }
}

@media (max-width: 1200px) {
  .chatv2-inner-cont {
    width: 320px;
  }
}

@media (max-width: 600px) {
  .chatv2-inner-cont {
    width: 100%;
  }
  .Jarvis {
    position: absolute;
    right: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
  }
  #chatv2-outer-cont {
    display: none;
  }
}

.home-outer-cont a {
  text-decoration: none;
}

.home-outer-cont a:hover {
  color: orange;
}

.name-title:hover {
  cursor: pointer;
  color: orange;
}

.home-outer-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.inner-cont-left {
  width: 50%;
  margin-right: 50px;
  padding: 10px;
  text-align: right;
}

.inner-cont-right {
  width: 50%;
  margin-left: 50px;
  padding: 25px 10px 10px 10px;
  text-align: left;
}

.inner-cont-right .home-subtitle:hover {
  color: orange;
}

.links-cont {
  padding: 0 !important;
}

.links-cont ul li {
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  position: relative;
}

.links-cont:hover ul {
  max-height: 600px;
  padding: 0 0 0 15px;
}

.links-cont ul {
  max-height: 0em;
  overflow: hidden;
  position: relative;
  top: 0px;
  transition: max-height 1s ease;
  padding: 0 0 0 15px;
}

.links-cont .list-box {
  padding-left: 25px;
}

@media (max-width: 600px) {
  .home-outer-cont {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .inner-cont-right {
    margin: 0;
    width: 100%;
  }
  .inner-cont-left {
    margin: 0;
    width: 100%;
    text-align: center;
  }
  .inner-cont-right .home-subtitle {
    margin-bottom: 10px;
    text-align: center;
  }
  .inner-cont-right .project-links-cont ul {
    font-size: 14px;
  }
  .inner-cont-right .social-list-box ul {
    font-size: 14px;
  }
  .inner-cont-right .home-subtitle h5 {
    font-size: 18px;
  }
  .links-cont:hover ul {
    padding: 0;
  }
  .links-cont ul {
    padding: 0;
    display: block;
  }
  .links-cont .list-box {
    text-align: left;
  }
  .links-cont .social-list-box {
    padding: 0;
    text-align: center;
  }
  .project-links-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
